import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { UqLayoutV2, UqAppBarV2, UqNavbarV2, UqFooterV2 } from '@uq-components';
import SEO from '../components/seo';

export default function ReportQualityIssue({ location }) {
  return (
    <UqLayoutV2>
      <UqAppBarV2>
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className="master-container report-quality-issue-page">
        <SEO title="Report Quality Issue" description="Report Quality Issue" />
        <Container fluid className="px-0 main">
          <Row noGutters className="no-header justify-content-center">
            <Col className="page-header-wrapper justify-content-center" />
          </Row>
          <Row noGutters>
            <Col>
              <Container className="text-center content-wrapper">
                <Row>
                  <Col className="layout-col">
                    <div className="mt-5">
                      <iframe
                        id="formFrame"
                        frameBorder="0"
                        height="880"
                        marginHeight="0"
                        marginWidth="0"
                        src={`/forms/report-quality-issue.html?appid=${location?.state?.appid}`}
                        title="request-demo-form"
                        width="320"
                      >
                        Loading…
                      </iframe>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
